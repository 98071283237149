import React from 'react';

type ErrorMessageProps = {
  message: string;
  textAlignment?: 'left' | 'center' | 'right';
  marginOverride?: string;
};

const ErrorMessage = ({
  message,
  textAlignment,
  marginOverride,
}: ErrorMessageProps) => {
  return (
    <span
      className={`block w-full text-r14 text-secondary-red-400 ${
        marginOverride ? marginOverride : 'my-4'
      } ${
        textAlignment === 'left'
          ? 'text-left'
          : textAlignment === 'right'
            ? 'text-right'
            : 'text-center'
      }`}
    >
      {message}
    </span>
  );
};

export default ErrorMessage;
