import {
  Check2FAResponse,
  CheckEmailResponse,
  CheckForceVerificationResponse,
  CheckLoginWithCodeResponse,
  CheckPasswordLoginResponse,
  CheckPasswordRegistrationResponse,
  CheckRegistrationWithCodeResponse,
  ForgotPasswordResponse,
  GenerateZendeskTokenResponse,
  GetCheckoutRedirectUrlResponse,
  GetEmailResponse,
  ResetPasswordResponse,
  VerifyPasswordResetLinkRequest,
  VerifyPasswordResetLinkResponse,
} from '@/shared/apiResponses/auth';
import { Language } from '@/shared/language';
import { RegistrationType } from '@/shared/registrationType';

import { apiRequest } from '@/api/common';

export async function checkEmail(
  email: string,
  hCaptchaResponse: string,
  language: Language,
  registrationType: RegistrationType | undefined
) {
  return (await apiRequest('/api/checkEmail', {
    email,
    hCaptchaResponse,
    language,
    registrationType,
  })) as CheckEmailResponse;
}

export async function checkPassword(
  email: string,
  password: string,
  hCaptchaResponse: string,
  language: Language
) {
  return (await apiRequest('/api/checkPassword', {
    email,
    password,
    hCaptchaResponse,
    language,
  })) as CheckPasswordLoginResponse;
}

export async function check2FA(totp: string) {
  return (await apiRequest('/api/check2FA', { totp })) as Check2FAResponse;
}

export async function checkLoginWithCode(
  email: string,
  code: string,
  hCaptchaResponse: string,
  language: string
) {
  return (await apiRequest('/api/checkLoginWithCode', {
    email,
    hCaptchaResponse,
    code,
    language,
  })) as CheckLoginWithCodeResponse;
}

export async function checkRegistrationWithCode(
  email: string,
  code: string,
  hCaptchaResponse: string
) {
  return (await apiRequest('/api/checkRegistrationWithCode', {
    email,
    hCaptchaResponse,
    code,
  })) as CheckRegistrationWithCodeResponse;
}

export async function checkPasswordRegistration(
  email: string,
  password: string,
  hCaptchaResponse: string,
  language: string
) {
  return (await apiRequest('/api/checkPasswordRegistration', {
    email,
    password,
    hCaptchaResponse,
    language,
  })) as CheckPasswordRegistrationResponse;
}

export async function checkForceVerification(
  email: string,
  experience: string,
  code: string
) {
  return (await apiRequest('/api/checkForceVerification', {
    email,
    experience,
    code,
  })) as CheckForceVerificationResponse;
}

export async function getEmail() {
  return (await apiRequest(
    '/api/getEmail',
    {},
    { method: 'GET' }
  )) as GetEmailResponse;
}

export async function generateZendeskJwt(language: string) {
  return (await apiRequest('/api/generateZendeskJWT', {
    language,
  })) as GenerateZendeskTokenResponse;
}

export async function forgotPassword(
  email: string,
  hCaptchaResponse: string,
  language: Language
) {
  return (await apiRequest('/api/forgotPassword', {
    email,
    hCaptchaResponse,
    language,
  })) as ForgotPasswordResponse;
}

export async function resetPassword(
  code: string,
  email: string,
  expiration: number,
  signature: string,
  newPassword: string,
  hCaptchaResponse: string
) {
  return (await apiRequest('/api/resetPassword', {
    code,
    email,
    expiration,
    signature,
    newPassword,
    hCaptchaResponse,
  })) as ResetPasswordResponse;
}

export async function getTeamsCheckoutRedirectUrl(
  adminEmail: string,
  adminName: string,
  adminPassword: string,
  businessName: string,
  freeTrial: string,
  hCaptchaResponse: string,
  invites: string | null,
  invoiceRequired: string,
  promoCode: string | null
) {
  const params: Record<string, string> = {
    adminEmail,
    adminPassword,
    adminName,
    businessName,
    freeTrial,
    hCaptchaResponse,
    invoiceRequired,
  };
  if (invites) {
    params.invites = invites;
  }
  if (promoCode) {
    params.promoCode = promoCode;
  }
  return (await apiRequest(
    '/api/teams/getCheckoutRedirectUrl',
    params
  )) as GetCheckoutRedirectUrlResponse;
}

export async function verifyPasswordResetLink(
  request: VerifyPasswordResetLinkRequest
) {
  return (await apiRequest(
    '/api/verifyPasswordResetLink',
    request
  )) as VerifyPasswordResetLinkResponse;
}
