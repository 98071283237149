'use client';

import { useTranslations } from 'next-intl';
import React from 'react';

import { useAppContext } from '@/configuration/AppContext';

import EvernoteSymbol from '../icons/EvernoteSymbol';
import WavingOmino from '../icons/WavingOmino';

type HeadingGenericProps = {
  experience:
    | 'login'
    | 'registration'
    | 'code-verification'
    | 'force-verification'
    | 'new-location'
    | 'password-recovery'
    | 'update-password'
    | 'two-factor-verification'
    | 'two-factor-backup-code'
    | 'redirect-saml'
    | 'teams-sign-up'
    | 'teams-sign-up-free-trial'
    | 'teams-invites';
  tagline?: string;
  showEmail?: boolean;
  hasLogo?: boolean;
  email?: string;
};

const HeadingGeneric = ({
  experience,
  tagline,
  showEmail,
  hasLogo,
  email,
}: HeadingGenericProps) => {
  const { email: appContextEmail } = useAppContext();
  const internalEmail = email ?? appContextEmail; // always use provided props (if not null or undefined)
  const emailLabel = internalEmail ? internalEmail : 'your email address';
  const hasRedirectIcon = experience === 'redirect-saml';

  const t = useTranslations('heading');

  return (
    <div className="flex flex-col items-center space-y-2">
      {hasLogo && (
        <EvernoteSymbol className="h-[72px] w-[72px] md:h-[100px] md:w-[100px]" />
      )}
      {hasRedirectIcon && <WavingOmino className="h-[150px] w-[150px]" />}
      {tagline && (
        <h3 className="text-sb10c uppercase text-grey-45">{tagline}</h3>
      )}
      <h1 className="text-sb24 text-grey-15 md:text-sb32">
        {t(`${experience}.title`)}
      </h1>
      <p className="text-r14 text-grey-15">
        {t(`${experience}.subtitle`)}{' '}
        {showEmail && (
          <span className="font-semibold">
            <br className="block md:hidden" />
            {emailLabel}.
          </span>
        )}
      </p>
    </div>
  );
};

export default HeadingGeneric;
